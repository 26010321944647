import { CardsList } from '../../../CardsList';
import {
  BackLink,
  ButtonLink,
  Foot,
  QuizWrap,
  Text,
} from '../../../../elements';
import { DiagnosisCard } from '../../elements';
import { schedule } from '../../../../../data/recommendations';
import { DosageList } from '../../../../DosageList';

interface Props {
  onBack: () => void;
}

const NoData = ({ onBack }: Props) => {
  const repeat = {
    ...schedule,
    title:
      'Рекомендовано проведение лабораторно-диагностических исследований с повторным приемом через 1 месяц',
  };

  const spasm = [
    {
      title: 'Мебеверин',
      dosage: '200 мг 2 раза в день',
    },
    {
      title: 'Гиосцина бутилбромид',
      dosage:
        'Внутрь: 10-20 мг 3-5 раз в день <br> Ректально: 10-20 мг 3-5 раз в день',
    },
    {
      title: 'Пинаверия бромид ',
      dosage: '50-100 мг 2-3 раза в день',
    },
  ];

  const antidiarhea = [
    {
      title: 'Смектит диоктаэдрический',
      dosage: '3 г до 3 раз в день',
    },
    {
      title: 'Лоперамид',
      dosage:
        'Начальная доза 4 мг, далее по 2 мг по потребности. Максимальная суточная дозировка - 12 мг',
    },
  ];

  const laxative = [
    {
      title: 'Лактулоза',
      dosage: '15-40 мл сиропа в сутки',
    },
    {
      title: 'Оболочка семян подорожника овального',
      dosage: '10 г в сутки',
    },
  ];

  return (
    <QuizWrap>
      <BackLink onClick={onBack}>Назад</BackLink>

      <DiagnosisCard>
        <div>
          Учитывая отсутствие результатов лабораторно-диагностических
          исследований, пациенту может быть рекомендована симптоматическая
          терапия, в зависимости от проявлений заболевания
        </div>
      </DiagnosisCard>

      <DosageList title="Спазмолитики:" list={spasm} />

      <DosageList title="Противодиарейные средства:" list={antidiarhea} />

      <DosageList title="Слабительные средства" list={laxative} />

      <Text>
      В рамках терапии абдоминального болевого синдрома важно подобрать
            препарат, который будет эффективно купировать боль и нормализовывать
            моторику кишечника. Обоснованным выбором можно считать оригинальный
            мебеверин. В рамках терапии абдоминального болевого синдрома важно
            подобрать препарат, который будет эффективно купировать боль и
            нормализовывать моторику кишечника. Обоснованным выбором можно
            считать оригинальный{' '}
            <a href="https://abbottpro.ru/academy/preparation/dyuspatalin" rel='noreferrer noopener' target="_blank">
              мебеверин
            </a>
            . Препарат устраняет широкий спектр симптомов (боль, вздутие,
            нарушения стула), за счет координации работы гладкомышечных клеток и
            восстановления моторики кишечника. <br />
            Учитывая его метаболизм без участия цитохромов печени, мебеверин
            может беспрепятственно назначаться с большинством препаратов.
            Длительность приема не ограничена, что свидетельствует о высоком
            профиле безопасности.
      </Text>

      <CardsList blueNotifications list={[repeat]} />

      <Foot $align="flex-end">
        <ButtonLink $type="light" to="/">
          Закончить прием
        </ButtonLink>
      </Foot>
    </QuizWrap>
  );
};

export { NoData };
