import { Heading, Notice, Text } from '../../../../elements';
import { CardsList } from '../../../CardsList';
import { ChecksList } from '../../../ChecksList';
import { RecommendationCard } from '../../../RecommendationCard';

import kidney from '../../../../../assets/img/kidney.png';
import tube from '../../../../../assets/img/tube.png';
import stomach from '../../../../../assets/img/stomach.png';
import lifestyle from '../../../../../assets/img/lifestyle.png';
import doctor from '../../../../../assets/img/doctor.png';
import syringe from '../../../../../assets/img/syringe.png';
import pills from '../../../../../assets/img/pills.png';
import biotic from '../../../../../assets/img/biotic.png';
import drugs from '../../../../../assets/img/drugs.png';
import search from '../../../../../assets/img/search.png';
import food from '../../../../../assets/img/food.png';
import schedule from '../../../../../assets/img/schedule.png';
import surgical from '../../../../../assets/img/surgical.png';
import ph from '../../../../../assets/img/phImpedance.png';
import { DiagnosisHeading } from '../../elements';
import { store } from '../../../../../app/store';
import {
  InterpretationItemType,
  Option,
} from '../../../../../types/interfaces';

export const pancreatitis = {
  title: 'Заболевания поджелудочной железы',
  code: 'Код по МКБ-10: K85-К86',
  content: () => {
    return (
      <>
        <DiagnosisHeading style={{ color: 'var(--accent)' }}>
          Возможные рекомендации по терапии
        </DiagnosisHeading>

        <CardsList
          list={[
            {
              title:
                'Проведение инструментальной диагностики (УЗИ, КТ, МРТ для визуализации поджелудочной железы)',
              icon: kidney,
            },
          ]}
        />
      </>
    );
  },
};

export const fecesBlood = {
  title: 'Наличие скрытой крови в кале',
  code: '',
  content: () => {
    return (
      <>
        <Text>
          Может указывать на наличие у пациента одного или нескольких из
          следующих состояний:
        </Text>

        <ChecksList
          list={[
            'Геморрой и/или анальная трещина',
            'Язва желудка или двенадцатиперскной кишки',
            'Дивертикулярная болезнь',
            'Ангиодисплазия слизистой оболочки ЖКТ',
            'Полипы толстой кишки',
            'Инфекция ЖКТ (например, амебиаз или инфекция, вызванная Campylobacter)',
            'Язвенный колит',
            'Прием некоторых лекарственных препаратов',
            'Болезнь Крона',
            'Рак толстой кишки',
          ]}
        />

        <Text>
          Рекомендуется проведение пациенту дополнительных исследований для
          исключения органических заболеваний ЖКТ
        </Text>

        <CardsList
          cols={2}
          hasBorder
          blueNotifications
          list={[
            {
              title: 'ЭГДС',
              icon: tube,
            },
            {
              title: 'Колоноскопия',
              icon: kidney,
            },
          ]}
        />

        <CardsList
          title="Полезные материалы"
          blueNotifications
          list={[
            {
              title: 'Памятки по подготовке к исследованиям:',
              icon: stomach,
              expandable: true,
              unlist: true,
              list: [
                'Колоноскопия <a href="/cdss/pdf/colono.pdf" target="_blank" rel="noopener">Скачать</a>',
                'УЗИ <a href="/cdss/pdf/uzi.pdf" target="_blank" rel="noopener">Скачать</a>',
                'Анализ кала <a href="/cdss/pdf/feces.pdf" target="_blank" rel="noopener">Скачать</a>',
              ],
            },
          ]}
        />
      </>
    );
  },
};

export const infection = {
  title: 'Кишечная инфекция (вызванная иным возбудителем, кроме Cl. difficile)',
  code: 'Код по МКБ-10: A00-A09',
  content: () => {
    return (
      <>
        <DiagnosisHeading style={{ color: 'var(--accent)' }}>
          Возможные рекомендации по терапии
        </DiagnosisHeading>

        <CardsList
          cols={2}
          hasBorder
          blueNotifications
          list={[
            {
              title:
                'Консультация врача-инфекциониста для выявления возбудителя и подбора соответствующей терапии',
              icon: doctor,
            },
            {
              title:
                'Лечебние питание и регидратационная терапия (применение глюкозо-солевых растворов)',
              icon: lifestyle,
            },
            {
              title: 'Дезинтоксикационная терапия (внутривенно)',
              icon: syringe,
            },
            {
              title: 'Назначение энтеросорбентов',
              icon: pills,
            },
            {
              title: 'Назначение пробиотиков',
              icon: biotic,
            },
            {
              title: 'Назначение противодиарейный препаратов (рацекадотрил)',
              icon: drugs,
            },
            {
              title:
                'Назначение спазмолитиков (мебеверин) для купирования спазма ЖКТ',
              icon: drugs,
            },
            {
              title: 'При необходимости: противорвотные препараты',
              icon: drugs,
            },
          ]}
        />

        <RecommendationCard
          item={{
            title:
              'С целью нормализации процессов пищеварения: назначение панкреатина или комбинированных препараратов',
            icon: biotic,
          }}
        />
      </>
    );
  },
};

export const colitis = {
  title: 'Микроскопический колит',
  code: 'Код по МКБ-10: K52.8',
  content: () => {
    return (
      <>
        <DiagnosisHeading style={{ color: 'var(--accent)' }}>
          Возможные рекомендации по терапии
        </DiagnosisHeading>

        <Text>Возможные варианты лечения включают в себя:</Text>

        <CardsList
          blueNotifications
          list={[
            {
              title:
                'Модификация образа жизни и максимальное исключение факторов, вызывающих воспаление в слизистой оболочке (курение, прием некоторых лекарственных препаратов)',
              icon: lifestyle,
            },
            {
              title: 'Медикаментозная терапия',
              icon: drugs,
              list: [
                'Топические глюкокортикостероиды (будесонид)',
                'Противодиареные препараты (лоперамид)',
              ],
            },
          ]}
          notifications={[
            `
                <b>При осложненных случаях иногда могут быть использованы также следующие группы препаратов:</b>
                <ul>
                    <li>Производные тиопурина (азатиоприн, меркаптопурин)</li>
                    <li>Биологическая терапия</li>
                </ul>
                `,
          ]}
        />
      </>
    );
  },
};

export const colorectalCancer = {
  title: 'Колоректальный рак',
  code: 'Код по МКБ-10: C20',
  content: () => {
    return (
      <>
        <CardsList
          blueNotifications
          list={[
            {
              title:
                'Для определения дальнейшей тактики диагностики и ведения пациента необходима консульация врача-онколога',
              icon: doctor,
            },
          ]}
        />
      </>
    );
  },
};

export const colonicPolyp = {
  title: 'Полип(-ы) тостой кишки',
  code: 'Код по МКБ-10: K63.5',
  content: () => {
    return (
      <>
        <CardsList
          blueNotifications
          list={[
            {
              title:
                'Для определения дальнейшей тактики ведения пациента рекомендуется провести гистологическое исследование ткани полипа',
              icon: search,
            },
          ]}
        />

        <Text>
          После проведения гистологического исследования рекомендуется
          рассмотреть возможность проведения эндоскопической полипэктомии.
        </Text>

        <Text>
          Мелкие единичные полипы чаще всего протекают асимптоматически и не влияют на клиническую картину СРК
        </Text>
      </>
    );
  },
};

export const celiac = {
  title: 'Целиакия',
  code: 'Код по МКБ-10: K90.0',
  content: () => {
    return (
      <>
        <Text>
          Для окончательного подтверждения диагноза рекомендуется проведение
          следующих исследований:
        </Text>

        <CardsList
          blueNotifications
          list={[
            {
              title:
                'Биопсия залуковичного отдела двенадцатиперстной кишки с последующей гистологической верификацией',
              icon: search,
            },
          ]}
        />

        <DiagnosisHeading>Возможные рекомендации по терапии</DiagnosisHeading>

        <CardsList
          blueNotifications
          cols={2}
          list={[
            {
              title: 'Консультация врача-диетолога и безглютеновая диета',
              icon: doctor,
            },
            {
              title: 'Коррекция дефицитов витаминов и минералов',
              icon: food,
            },
            {
              title:
                'Регулярное медицинское наблюдение (контроль уровня антител, проведение денситометрии)',
              icon: schedule,
            },
            {
              title:
                'В осложненных случаях может потребоваться медикаментозная терапия глюкокортикостероидами и иммуносупрессорами',
              icon: biotic,
            },
          ]}
        />
      </>
    );
  },
};

export const crohn = {
  title: 'Болезнь Крона',
  code: 'Код по МКБ-10: K50',
  content: () => {
    return (
      <>
        <DiagnosisHeading style={{ color: 'var(--accent)' }}>
          Возможные рекомендации по терапии
        </DiagnosisHeading>

        <CardsList
          blueNotifications
          notifications={[
            'Выбор вида консервативного или хирургического лечения определяется тяжестью атаки, протяженностью поражения толстой кишки и другими факторами',
          ]}
        />

        <CardsList
          blueNotifications
          list={[
            {
              title:
                'Медикаментозная терапия (в зависимости от степени тяжести атаки):',
              list: [
                'Системные и топические ГКС в комбинации с иммуносуппрессорами',
                'Биологические генно-инженерные препараты (и-ФНО, ингибиторы ИЛ, селективные имменидепрессанты)',
                'Антибиотики',
                'Вспомонательные препараты для коррекции анемии, белковы и электролитных нарушений и др. сопутствующих нарушений',
              ],
              icon: biotic,
            },
            {
              title: 'Хирургическое лечение',
              icon: surgical,
            },
            {
              title: 'Диетотерапия',
              icon: food,
            },
            {
              title: 'Психосоциальная поддержка',
              icon: doctor,
            },
          ]}
        />
      </>
    );
  },
};

export const erosiveColitis = {
  title: 'Эрозивный колит / энтерит',
  code: 'Код по МКБ-10: K52',
  content: () => {
    return (
      <>
        <Text>
          Чаще всего данная картина на илеоколоноскопии может являться
          индивидуальной реакцией пациента на подготовку к проведению
          исследования и не дает оснований заподозрить ВЗК.
        </Text>

        <CardsList
          blueNotifications
          list={[
            {
              title:
                'К возможным причинам появления эрозий также можно отнести: недавно перенесенные или текущие кишечные инфекции, прием нестероидных противовоспалительных препаратов (НПВС)',
              icon: stomach,
            },
            {
              title:
                'Рекомендуется проведение пациенту микроскопического исследования для исключения микроскопического <br/> колита и повторное проведение илеоколоноскопии через 6-12 месяцев',
              icon: ph,
            },
          ]}
        />
      </>
    );
  },
};

export const diverticular = {
  title: 'Дивертикулярная болезнь',
  code: 'Код по МКБ-10: K57',
  content: () => {
    return (
      <>
        <DiagnosisHeading style={{ color: 'var(--accent)' }}>
          Возможные рекомендации по терапии
        </DiagnosisHeading>

        <CardsList
          blueNotifications
          notifications={[
            'Выбор вида консервативного или хирургического лечения определяется тяжестью атаки, протяженностью поражения толстой кишки и другими факторами',
          ]}
        />

        <CardsList
          blueNotifications
          list={[
            {
              title: 'Медикаментозная терапия:',
              list: [
                'Противодиарейные препараты',
                'Пищевые волокна',
                'Спазмолитики',
                'Месалазин',
              ],
              icon: biotic,
            },
            {
              title:
                'Хирургическое лечение (при острых состояниях, кровотечениях и осложненнии абсцессом)',
              list: [
                'Илеоколоноскопия с биопсией из толстого и тонкого кишечника',
              ],
              icon: surgical,
            },
            {
              title: 'Диетотерапия',
              icon: food,
            },
          ]}
        />
      </>
    );
  },
};

export const сlostridial = {
  title: 'Клостридиальная инфекция',
  code: 'Код по МКБ-10: A04.7',
  content: () => {
    return (
      <>
        <DiagnosisHeading style={{ color: 'var(--accent)' }}>
          Возможные рекомендации по терапии
        </DiagnosisHeading>

        <CardsList
          blueNotifications
          list={[
            {
              title: '',
              list: [
                'Отмена принимаемой антибактериальной терапии, служащей фактором риска, для улучшения клинического ответа на лечение и снижения риска рецидивов',
                'Подбор противоклостридиальной терапии в соответствии со степенью тяжести C. difficile-ассоциированной болезни',
                'Не рекомендуется использовать препараты, замедляющие моторику кишки (например, лоперамид) у пациентов <br/> с C. difficile-ассоциированной болезнью для снижения риска развития осложнений',
                'Регидратационная терапия при наличии признаков нарушения водно-электролитного баланса',
              ],
              icon: drugs,
            },
          ]}
        />

        <Text>
          В осложненных случаях при отсутствии клинического улучшения или
          повышении уровня лактата или лейкоцитозе:
        </Text>

        <CardsList
          blueNotifications
          list={[
            {
              title:
                'Консультация хирурга для рассмотрения возможности проведения хирургического вмешаельства',
              icon: surgical,
            },
          ]}
        />

        <Text>
          Пациентам с тяжелым и осложненным течением клостридиальной инфекции,
          рефрактерностью к консервативному лечению и невозможностью проведения
          оперативного лечения:
        </Text>

        <CardsList
          blueNotifications
          list={[
            {
              title: 'Трансплантация фекальной микробиоты (ТФМ)',
              icon: syringe,
            },
          ]}
        />
      </>
    );
  },
};

export const bacterial = {
  title: 'Синдром избыточного бактериального роста',
  code: 'Код по МКБ-10: R19.8',
  content: () => {
    return (
      <>
        <DiagnosisHeading style={{ color: 'var(--accent)' }}>
          Возможные рекомендации по терапии
        </DiagnosisHeading>

        <CardsList
          blueNotifications
          list={[
            {
              title: 'Назначение диеты (элементарная*, low-FODMAP и др.)',
              icon: food,
            },
            {
              title: 'Проведение антибактериальной терапии',
              icon: biotic,
            },
          ]}
        />

        <CardsList
          blueNotifications
          list={[
            {
              title:
                'Назначение пробиотиков для снижения риска развития антибиотико-ассоциированной диареи (ААД) и инфекции, вызванной C.difficile',
              icon: pills,
            },
          ]}
        />

        <Notice>
          *элементарная диета представляет собой жидкие смеси или порошки для
          разведения в жидкости, состоящие из легкоусваиваемых белков,
          аминокислот, жиров и углеводов, обогащенные витаминами. Данные
          питательные вещества всасываются преимущественно в проксимальном
          отделе тонкой кишки, что ограничивает их доставку к бактериям в
          дистальном отделе.
        </Notice>
      </>
    );
  },
};

export const lactase = {
  title: 'Лактазная недостаточность',
  code: 'Код по МКБ-10: E73',
  content: () => {
    return (
      <>
        <DiagnosisHeading style={{ color: 'var(--accent)' }}>
          Возможные рекомендации по терапии
        </DiagnosisHeading>

        <CardsList
          blueNotifications
          notifications={[
            'Выбор вида консервативного или хирургического лечения определяется тяжестью атаки, протяженностью поражения толстой кишки и другими факторами',
          ]}
        />

        <CardsList
          blueNotifications
          cols={2}
          list={[
            {
              title: 'Консультация врача-диетолога и безглютеновая диета',
              icon: doctor,
            },
            {
              title: 'Коррекция дефицитов витаминов и минералов',
              icon: food,
            },
            {
              title: 'Назначение пробиотиков и пребиотиков',
              icon: pills,
            },
            {
              title:
                'Назначение препаратов лактазы при приеме молочных продуктов',
              icon: biotic,
            },
          ]}
        />
      </>
    );
  },
};

export const ulcerative = {
  title: 'Язвенный колит',
  code: 'Код по МКБ-10: K51',
  content: () => {
    return (
      <>
        <DiagnosisHeading style={{ color: 'var(--accent)' }}>
          Возможные рекомендации по терапии
        </DiagnosisHeading>

        <CardsList
          blueNotifications
          notifications={[
            'Выбор вида консервативного или хирургического лечения определяется тяжестью атаки, протяженностью поражения толстой кишки и другими факторами',
          ]}
        />

        <CardsList
          blueNotifications
          list={[
            {
              title:
                'Медикаментозная терапия (в зависимости от степени тяжести атаки):',
              list: [
                'Месалазин (ректально или перорально)',
                'ГКС (ректально, перорально или в/в)',
                'Биологическая терапия препаратами моноклональных АТ',
              ],
              icon: drugs,
            },
            {
              title: 'Диетотерапия',
              list: [
                'Особая углеводная диета',
                'Средиземноморская диета',
                `Диета low FODMAP 
                <div>С низким содержанием олиго-ди-моносахаридов (лактозы, фруктозы, фруктанов, галактанов) и полиолов (сорбитола, ксилитола, маннитола)</div>
                <a href="/cdss/pdf/diet.pdf">Памятка <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <path d="M12.8434 9.68492L8.65273 5.66203L9.75742 4.60156L15.834 10.4349L9.75742 16.2682L8.65273 15.2078L12.8434 11.1849H3.33398V9.68492H12.8434Z" fill="#009CDE"/>
                </svg></a>`,
              ],
              icon: stomach,
            },
            {
              title:
                'Хирургическое лечение (колпроктэктомия или тотальная колэктомия)',
              icon: surgical,
            },
          ]}
        />

        <CardsList
          blueNotifications
          cols={2}
          list={[
            {
              title:
                'Хирургическое лечение (колпроктэктомия или тотальная колэктомия)',
              icon: surgical,
            },
            {
              title: 'Психосоциальная поддержка',
              icon: doctor,
            },
          ]}
        />
      </>
    );
  },
};

export const lowHemo = {
  title: 'Гемоглобин понижен',
  subtitle:
    'Снижение уровня Hb может наблюдаться у пациентов в следующих случаях:',
  content: () => {
    return (
      <>
        <ChecksList
          list={[
            'Железодефицитная анемия',
            'Поражение костного мозга',
            'Обширная кровопотеря или наличие внутреннего кровотечения',
            'Заболевания почек, характеризующиеся снижением их функции',
            'Наличие злокачественных новообразований',
            'Недостаточное употребление в пищу белка, витаминов, железа',
          ]}
        />

        <Text>
          Данный список возможных причин не является исчерпывающим, возможны
          другие причины
        </Text>
      </>
    );
  },
};

export const highLeukocytes = {
  title: 'Кол-во лейкоцитов выше нормы',
  subtitle:
    'Повышенное количество лейкоцитов может наблюдаться у пациентов в следующих случаях:',
  content: () => {
    return (
      <>
        <ChecksList
          list={[
            'Наличие инфекции',
            'Интенсивная физическая нагрузка',
            'Воспалительные заболевания (например, ревматоидный артрит, колит и др)',
            'Прием некоторых лекарственных препаратов (кортикостероиды)',
            'Наличие злокачественных новообразований',
            'Токсические реакции (при отравлении или воздействии на организм определенных токсинов)',
            'Разрушение тканей организма (например, при инфаркте, ожогах и др)',
            'Состояние после спленэктомии',
          ]}
        />

        <Text>
          Данный список возможных причин не является исчерпывающим, возможны
          другие причины
        </Text>
      </>
    );
  },
};

export const highEosinophils = {
  title: 'Кол-во эозинофилов выше нормы',
  subtitle:
    'Повышенное количество эозинофилов может наблюдаться у пациентов в следующих случаях:',
  content: () => {
    return (
      <>
        <ChecksList
          list={[
            'Аллергические реакции',
            'Заболевания, связанные с эозинофилами (например, эозинофильный пневмонит, эозинофильный эзофагит)',
            'Паразитарные и инфекционные заболевания (например, аскаридоз, этеробиоз)',
            'Определенные виды рака',
            'Атопические заболевания кожи (такие, как экзема, псориаз, дерматиты)',
            'Хронические воспалительные заболевания',
            'Аутоиммунные заболевания (например, болезнь Крона или васкулит)',
          ]}
        />

        <Text>
          Данный список возможных причин не является исчерпывающим, возможны
          другие причины
        </Text>
      </>
    );
  },
};

export const cReactiveElevated = {
  title: 'С-реактивный белок повышен',
  content: () => {
    return (
      <>
        <Text>
          У пациентов, страдающих СРК, чаще всего наблюдается нормальный уровень
          С-реактивного белка.
        </Text>

        <Text>
          Умеренно повышенный уровень С-реактивного белка может
          свидетельствовать о наличии у пациента коморбидного состояния: СРК +
          дополнительное заболевание.
        </Text>

        <Text>
          Повышение С-РБ может наблюдаться у пациентов в следующих случаях:
        </Text>

        <ChecksList
          list={[
            'Острые вирусные и бактериальные инфекции',
            'Аутоиммунные заболевания (ревматоидный артрит, системный васкулит, болезнь Крона, язвенный колит и др.)',
            'Очаговые инфекции (хр. тонзилит)',
            'Воспалительные заболевания органов малого таза',
            'Инфекционный эндокардит',
            'Злокачественные новообразования и метастазы ',
            'Миеломная болезнь',
            'Туберкулез',
            'Крупные травмы (переломы) и состояния после хирургических вмешательств',
            'Ожоги',
            'Сепсис и перитонит',
            'Ишемические состояния (ИМ или ОКС)',
            'Ожирение',
            'Сахарный диабет',
            'Хронические заболевания печени и почек',
          ]}
        />

        <Text>
          Данный список возможных причин не является исчерпывающим, возможны
          другие причины
        </Text>
      </>
    );
  },
};

export const highCalprotectin = {
  title: 'Уровень кальпротектина кала более 5 норм',
  subtitle:
    'Повышенный уровень кальпротектина в кале может указывать на наличие у пациента одного или нескольких из следующих состояний:',
  content: () => {
    return (
      <>
        <ChecksList
          list={[
            'Язвенный колит',
            'Болезнь Крона',
            'Инфекционные колиты',
            'Инфекционные энтериты',
            'Дивертикулярная болезнь (обострение)',
            'Неспецифический колит (например, радиационный или химический колит)',
            'Рак толстой кишки',
            'Целиакия (у некоторых пациентов)',
            'Прием некоторых лекарственных препаратов (НПВС, ингибиторы протонной помпы, стероидные ЛС и др.)',
          ]}
        />
      </>
    );
  },
};

const checkAnswer = (id: string, answer: string | number) => {
  const question = store
    .getState()
    .srkExaminations.list.find((el) => el.id === id);

  if (!question || !question.value) return false;

  // Проверка на множественное значение
  try {
    const parsedAnswers = JSON.parse(
      question.value.value as string
    ) as Option[];
    return parsedAnswers.some((el) => el.value === answer);
  } catch (e) {
    return question.value.value === answer;
  }
};

export const diseasesList = () => {
  const list: InterpretationItemType[] = [];

  if (checkAnswer('feces_analysis', 'В кале обнаружена скрытая кровь')) {
    list.push(fecesBlood);
  }

  if (checkAnswer('feces_analysis', 'В кале выявлена кишечная инфекция')) {
    list.push(infection);
  }

  if (
    checkAnswer('feces_analysis', 'В кале выявлены токсины А,В к Cl.difficile')
  ) {
    list.push(сlostridial);
  }

  if (
    checkAnswer(
      'blood_analysis',
      'Повышенный уровень IgA и IgG к тканевой трансглутаминазе'
    )
  ) {
    list.push(celiac);
  }

  if (
    checkAnswer(
      'additional_analysis',
      'Положительный водородный дыхательный тест на СИБР'
    )
  ) {
    list.push(bacterial);
  }

  if (
    checkAnswer(
      'additional_analysis',
      'Положительный водородный дыхательный тест на лактазную недостаточность'
    )
  ) {
    list.push(lactase);
  }

  if (
    checkAnswer('feces_analysis', 'Уровень кальпротектина кала более 5 норм')
  ) {
    list.push(highCalprotectin);
  }

  if (
    checkAnswer(
      'instrumental_analysis',
      'В ходе колоноскопии выявлены признаки повреждения кишечника'
    )
  ) {
    if (checkAnswer('intestine_damage', 'Признаки язвенного колита')) {
      list.push(ulcerative);
    }

    if (checkAnswer('intestine_damage', 'Признаки болезни Крона')) {
      list.push(crohn);
    }

    if (checkAnswer('intestine_damage', 'Признаки колоректального рака')) {
      list.push(colorectalCancer);
    }

    if (checkAnswer('intestine_damage', 'Признаки полипов толстой кишки')) {
      list.push(colonicPolyp);
    }

    if (
      checkAnswer('intestine_damage', 'Признаки эрозивного колита/энтерита')
    ) {
      list.push(erosiveColitis);
    }

    if (checkAnswer('intestine_damage', 'Признаки дивертикулярной болезни')) {
      list.push(diverticular);
    }
  }

  if (checkAnswer('colitis', 1)) {
    list.push({
      ...colitis,
      title: 'Микроскопический колит (коллагеновая форма)',
    });
  }

  if (checkAnswer('colitis', 2)) {
    list.push({
      ...colitis,
      title: 'Микроскопический колит (лимфоцитарная форма)',
    });
  }

  if (checkAnswer('colitis', 3)) {
    list.push({
      ...colitis,
      title: 'Микроскопический колит (неполная форма)',
    });
  }

  if (checkAnswer('feces_consistency', 1)) {
    list.push(pancreatitis);
  }

  if (checkAnswer('blood_analysis', 'Уровень гемоглобина понижен')) {
    list.push(lowHemo);
  }

  if (checkAnswer('blood_analysis', 'Количество лейкоцитов повышено')) {
    list.push(highLeukocytes);
  }

  if (checkAnswer('blood_analysis', 'Количество эозинофилов повышено')) {
    list.push(highEosinophils);
  }

  if (checkAnswer('blood_analysis', 'Повышен С-реактивный белок')) {
    list.push(cReactiveElevated);
  }

  return list;
};

export const notificationsList = () => {
  const list: string[] = [];

  if (checkAnswer('feces_consistency', 0)) {
    list.push(`
    В случае наличия у пациента диареи/неоформленного стула, анализ на панкретическую эластазу может давать ложноположительный результат. 
    Рекомендуется повторить анализ.
      `);
  }

  if (
    checkAnswer(
      'instrumental_analysis',
      'При проведении УЗИ органов брюшной полости найдены отклонения от нормы'
    )
  ) {
    list.push(`
      Рекомендуется продолжить диагностический поиск на предмет наличия у пациента заболеваний печени, поджелудочной железы, желчного пузыря и селезенки.
      Наличие отклонений на УЗИ органов брюшной полости не исключает наличия у пациента синдрома раздраженного кишечника.
      Деформация желчного пузыря, косвенные признаки дискинезии желчевыводящих путей (ДЖВП) - не являются самостоятельной патологией, но могут быть фоном для билиарной дисфункции и сопутствовать СРК.
      `);
  }

  if (
    checkAnswer(
      'instrumental_analysis',
      'При проведении ЭГДС найдены отклонения от нормы'
    )
  ) {
    list.push(`
      Рекомендуется продолжить диагностический поиск на предмет наличия у пациента заболеваний пищевода и желудка. 
      Наличие отклонений на ЭГДС не исключает наличия у пациента синдрома раздраженного кишечника.
      С дифференциальной диагностикой и подходами к лечению ГЭРБ можно ознакомиться в соответствующем <a style="display: inline; font-size: 20px;" href="/cdss/secondary" target="_blank" rel="noopener">разделе сайта.</a>
    `);
  }

  return list;
};
