import {
  BackLink,
  ButtonLink,
  Column,
  ColumnsWrap,
  Foot,
  Item,
  List,
  MarkedItem,
  MarkedList,
  Notice,
  QuizWrap,
  Text,
} from '../../../../elements';
import { DiagnosisCard, DiagnosisHeading } from '../../elements';
import styled from 'styled-components';

interface Props {
  onBack: () => void;
}

const ResultButtonLink = styled(ButtonLink)`
   max-width: 100%;
  margin: 32px 0;

  @media (max-width: 991px) {
    margin: 24px 0;
  }
`;

const RefractoryFlow = ({ onBack }: Props) => {
  return (
    <QuizWrap>
      <ColumnsWrap>
        <Column>
          <BackLink onClick={onBack}>Назад</BackLink>

          <DiagnosisHeading>Вероятный диагноз</DiagnosisHeading>

          <DiagnosisCard>
            Диагноз: К21.0 ГЭРБ, рефрактерное течение
          </DiagnosisCard>

          <Text>
            Последовательность действий по преодолению рефрактерности в лечении
            ГЭРБ может включать в себя, но не ограничиваться следующими
            рекомендациями:
          </Text>

          <MarkedList>
            <MarkedItem>
              Обсудить с пациентом и удостовериться в том, что все ранее данные
              рекомендации по приему лекарственных препаратов и изменению образа
              жизни соблюдались и выполнялись
            </MarkedItem>
            <MarkedItem>
              Рассмотреть вопрос о повторном проведении ЭГДС для исключения
              осложнений ГЭРБ (пищевод Баррета, наличие стриктур пищевода) и
              онкологических заболеваний (АКП)
            </MarkedItem>
            <MarkedItem>
              Назначение пациенту другого ИПП или перевод его на схему приема
              дважды в день (утром и вечером) на срок до 2 месяцев
            </MarkedItem>
          </MarkedList>
        </Column>

        <Column>
          <DiagnosisHeading>Терапия</DiagnosisHeading>

          <Text>
            <b>
              В случае, если в медицинском учреждении имеется необходимое
              оборудование, рекомендуется повторное проведение
              pH-импедансометрии с ИПП и проверка эффективности ИПП
            </b>
          </Text>

          <MarkedList>
            <MarkedItem>
              Отсутствие выраженных нарушений.В этом случае пациенту
              рекомендуется консультация психиатра и возможное назначение
              препаратов, купирующих болевой синдром и жжение
            </MarkedItem>
            <MarkedItem>
              Наличие кислотных рефлюксов, несмотря на проводимую терапию ИПП.В
              этом случае рекомендуется еще раз удостовериться в правильном
              приеме пациентом назначенного лечения ИПП. Если все рекомендации
              выполняются верно, можно рассмотреть возможность:
              <List>
                <Item>замены принимаемого препарата в рамках группы ИПП</Item>
                <Item>
                  добавления к проводимой ИПП-терапии приема блокаторов
                  H2-гистаминовых рецепторов на ночь
                </Item>
                <Item>хирургического лечения*</Item>
                <Item>
                  эндоскопического лечения (метод Стретта или трансоральная
                  фундопликация)
                </Item>
              </List>
            </MarkedItem>

            <MarkedItem>
              Наличие слабокислых рефлюксов. <br></br>В этом случае можно
              рассмотреть возможность назначения:
              <List>
                <Item>
                  препаратов, снижающих количество преходящего расслабления НПС
                  (итоприда гидрохлорид)
                </Item>
                <Item>препаратов урсодезоксихолиевой кислоты (УДХК)</Item>
                <Item>препаратов, купирующих болевой синдром</Item>
                <Item>хирургического лечения*</Item>
                <Item>
                  эндоскопического лечения (метод Стретта или трансоральная
                  фундопликация)
                </Item>
              </List>
            </MarkedItem>
          </MarkedList>

          <Text>
            <b>
              В случае, если в медицинском учреждении ОТСУТСТВУЕТ необходимое
              для проведения pH-импедансометрии оборудование,
            </b>{' '}
            пациенту можно рекомендовать следующие опции терапии:
          </Text>

          <List>
            <Item>
              Назначение итоприда гидрохлорида, если не назначался ранее
            </Item>
            <Item>
              У пациентов, основной жалобой которых является изжога, можно
              рассмотреть добавление к проводимой ИПП-терапии приема блокаторов
              H2-гистаминовых рецепторов на ночь (с перерывами между курсами,
              чтобы избежать развития тахифилаксии)
            </Item>
            <Item>
              В случае, если это не оказывает должного эффекта, можно
              рассмотреть назначение пациенту препаратов, купирующих болевой
              синдром
            </Item>
          </List>

          <Notice>
            *Хирургическое лечение не показано пациентам, у которых наблюдается
            абсолютное отсутствие ответа на ранее проводимую терапию ИПП
          </Notice>

          <ResultButtonLink to="/secondary/">
            Результаты дополнительных исследований
          </ResultButtonLink>

        <Foot $align="flex-end">
          <ButtonLink $type='light' to="/">Закончить прием</ButtonLink>
        </Foot>
        </Column>

      </ColumnsWrap>
    </QuizWrap>
  );
};

export { RefractoryFlow };
